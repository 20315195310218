/**
* Generated automatically at built-time (2025-01-31T10:39:41.149Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "ulkokeittio-black-friday",templateKey: "sites/106-1d1f41c2-8fc7-4c24-9deb-5adf74acf30e"};
/**
* Generated automatically at built-time (2025-01-31T10:39:41.003Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "toimitamme-ulkokeittiosi-espanjaan",templateKey: "sites/106-0583666e-a324-44a4-a000-15ef1f0f1285"};
/**
* Generated automatically at built-time (2025-01-31T10:39:41.164Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "kesakeittio-ideota",templateKey: "sites/106-401fdc9d-e53f-48d3-bb03-4b65a54bdd2c"};